import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';

import { Link } from 'gatsby';

import '../infoother.css';
import { CalendlyLink } from '../../Calendly';

const InfoOtherSection = ({ sectionWrapper, title, description, textArea }) => {
  return (
    <Box {...sectionWrapper}>
      <Box className="multi_sections_container BookDemo_info_container">
        <Container>
          <Box {...textArea}>
            {/* <Heading
              {...title}
              content="Unlock The True Potential Of Your Unstructured Content With RAP AI"
              className="text_center"
            /> */}
            <div className="empty_space40" />
            <Text
              {...description}
              className="text_center mt0"
              content="Achieve peak operational efficiencies with our Next-Gen Platforms RAPFlow and RAPBot. Curious to see it in action and learn how it can help you? Book a slot with us using the widget below and let’s discuss how we can help transform your business and disrupt your industry."
            />
          </Box>
          <CalendlyLink />
        </Container>
      </Box>
    </Box>
  );
};

InfoOtherSection.propTypes = {
  sectionWrapper: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  textArea: PropTypes.object,
};

InfoOtherSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    // pt: ['25px', '25px', '25px', '30px', '30px'],
    // pb: ['25px', '25px', '25px', '30px', '30px'],
    id: 'info_other',
    backgroundColor: '#f9fbfd',
  },
  textArea: {
    width: ['95%', '95%', '85%', '85%', '85%'],
    margin: '0 auto',
  },
  title: {
    fontSize: ['30px', '30px', '35px', '40px', '45px'],
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mt: 0,
    mb: 0,
    pt: '35px',
    pb: '20px',
  },
  description: {
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    // color: '#343d48cc',
    color: '#252525',
    lineHeight: '1.5',
    mt: '20px',
    mb: '20px',
  },
};

export default InfoOtherSection;
